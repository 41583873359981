var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import ListItem, { ListItemLabel } from '../ListItem';
import * as S from './styles';
function getFormattedAmount(props) {
    switch (props.transactionType) {
        case 'deposit':
            return `+ ${props.amount}`;
        case 'charge':
            return `- ${props.amount}`;
        default:
            return `+ ${props.amount}`;
    }
}
const ListTransactionItem = forwardRef(function ListTransactionItem(_a, ref) {
    var { className, subLabel, label, amount, transactionType } = _a, rest = __rest(_a, ["className", "subLabel", "label", "amount", "transactionType"]);
    const amountLabel = getFormattedAmount({ amount, transactionType });
    return (_jsxs(ListItem, Object.assign({ ref: ref, className: className }, rest, { children: [subLabel ? (_jsxs(S.LabelContainer, { children: [_jsx(S.SubLabel, { children: subLabel }), _jsx(S.Label, { children: label })] })) : null, !subLabel ? _jsx(ListItemLabel, { children: label }) : null, _jsx(S.FlexRight, { children: _jsxs(S.AmountContainer, Object.assign({ transactionType: transactionType }, { children: [_jsx(S.AmountLabel, { children: amountLabel }), _jsx(S.CurrencyLabel, { children: "EUR" })] })) })] })));
});
export default ListTransactionItem;
