var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Root, Portal } from '@radix-ui/react-dialog';
import { forwardRef } from 'react';
import { PopoverOverlay, PopoverContentContainer } from './styles';
const Popover = forwardRef(function Popover(_a, forwardedRef) {
    var { children, alignContent = 'center' } = _a, rest = __rest(_a, ["children", "alignContent"]);
    return (_jsx(Root, Object.assign({}, rest, { children: _jsxs(Portal, { children: [_jsx(PopoverOverlay, {}), _jsx(PopoverContentContainer, Object.assign({ alignContent: alignContent, ref: forwardedRef }, { children: children }))] }) })));
});
export default Popover;
