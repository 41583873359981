import { ThemeProvider } from 'pqbc-vas-design-system';
import { injectNativeBridgeMock } from 'pqbc-vas-native-bridge';
import { BrowserRouter } from 'react-router-dom';

import { isDev } from '../utils';

import { App, GlobalStyle } from './components';

if (isDev()) {
  injectNativeBridgeMock({ language: 'en' });
}

const Root = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <GlobalStyle />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Root;
