var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable class-methods-use-this */
import { createPayconiqReceiptAndTopupUrl } from "./payconiq";
import { invoke, isAndroid } from "./core";
import { NATIVE_METHODS, NATIVE_CALLBACKS } from "./constants";
import { register } from "./core/register";
class VASNativeBridge {
    startPayconiqPaymentFlow(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const payconiqUrl = createPayconiqReceiptAndTopupUrl(options);
            return invoke(NATIVE_METHODS.START_PAYCONIQ_PAYMENT_FLOW, payconiqUrl);
        });
    }
    closeWebview() {
        return invoke(NATIVE_METHODS.CLOSE_WEBVIEW);
    }
    openUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return invoke(NATIVE_METHODS.OPEN_URL, url);
        });
    }
    openExternalUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return invoke(NATIVE_METHODS.OPEN_EXTERNAL_URL, url);
        });
    }
    startAuthorizationCodeGrant(args) {
        return __awaiter(this, void 0, void 0, function* () {
            const stringifiedArgs = JSON.stringify(args);
            const code = yield invoke(NATIVE_METHODS.START_OAUTH, stringifiedArgs);
            return code;
        });
    }
    getLanguage() {
        return __awaiter(this, void 0, void 0, function* () {
            const language = yield invoke(NATIVE_METHODS.GET_LANGUAGE);
            return language ? language.toLowerCase() : null;
        });
    }
    saveToken(key, value) {
        return __awaiter(this, void 0, void 0, function* () {
            const object = { key, token: value };
            const parsedEventArgs = JSON.stringify(object);
            return invoke(NATIVE_METHODS.SAVE_TOKEN, parsedEventArgs);
        });
    }
    removeToken(key) {
        return __awaiter(this, void 0, void 0, function* () {
            return invoke(NATIVE_METHODS.REMOVE_TOKEN, key);
        });
    }
    getToken(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield invoke(NATIVE_METHODS.GET_TOKEN, key);
            return token;
        });
    }
    getAppVersion() {
        return __awaiter(this, void 0, void 0, function* () {
            const version = yield invoke(NATIVE_METHODS.GET_APP_VERSION);
            return version;
        });
    }
    /**
     * Returns an `AppInfo` object, containing debugging info
     * which can be sent to support.
     */
    getAppInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const appInfo = yield invoke(NATIVE_METHODS.GET_APP_INFO);
            if (appInfo) {
                return JSON.parse(appInfo);
            }
            return null;
        });
    }
    /**
     * Returns an `TransactionReport` object, containing the transaction data.
     */
    getTransactionReport() {
        return __awaiter(this, void 0, void 0, function* () {
            const transactionReport = yield invoke(NATIVE_METHODS.GET_TRANSACTION_REPORT);
            if (transactionReport) {
                return JSON.parse(transactionReport);
            }
            return null;
        });
    }
    trackEvent(eventName, parameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const eventArgs = Object.assign({ name: eventName }, (parameters
                ? {
                    parameters: Object.assign({}, parameters),
                }
                : {}));
            const parsedEventArgs = isAndroid() ? JSON.stringify(eventArgs) : eventArgs;
            return invoke(NATIVE_METHODS.TRACK_EVENT, parsedEventArgs);
        });
    }
    onPaymentFinished() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => register(NATIVE_CALLBACKS.PAYMENT_FINISHED, resolve));
        });
    }
    onDeeplink() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => register(NATIVE_CALLBACKS.START_DEEP_LINK, resolve));
        });
    }
    share(content) {
        return __awaiter(this, void 0, void 0, function* () {
            const shareContent = content;
            // on android the content (url) is used as message
            if (isAndroid()) {
                shareContent.content = content.message;
            }
            const parsedContent = JSON.stringify(shareContent);
            return invoke(NATIVE_METHODS.SHARE_CONTENT, parsedContent);
        });
    }
    syncTickets(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const stringifiedArgs = JSON.stringify(data);
            return invoke(NATIVE_METHODS.SYNC_TICKETS, stringifiedArgs);
        });
    }
    openTicket(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return invoke(NATIVE_METHODS.OPEN_TICKET, id);
        });
    }
    deleteAllTickets() {
        return __awaiter(this, void 0, void 0, function* () {
            return invoke(NATIVE_METHODS.DELETE_ALL_TICKETS);
        });
    }
    markInUse() {
        return __awaiter(this, void 0, void 0, function* () {
            return invoke(NATIVE_METHODS.MARK_IN_USE);
        });
    }
}
export default VASNativeBridge;
