import { darkModeColors, defaultColors, lightModeColors } from './colors';
import { defaultTypography } from './typography';
export const defaultTheme = {
    colors: defaultColors,
    typography: defaultTypography,
};
export const lightTheme = {
    colors: lightModeColors,
    typography: defaultTypography,
};
export const darkTheme = {
    colors: darkModeColors,
    typography: defaultTypography,
};
export default defaultTheme;
