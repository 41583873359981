var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Flex from '../Flex';
import { Paragraph } from '../Typography';
import * as S from './styles';
const Note = forwardRef(function Card(_a, ref) {
    var { icon, children } = _a, rest = __rest(_a, ["icon", "children"]);
    return (_jsx(S.Note, Object.assign({}, rest, { ref: ref }, { children: _jsxs(Flex, Object.assign({ alignItems: 'center', justifyContent: 'flex-start' }, { children: [_jsx(S.Icon, { children: icon }), _jsx(Paragraph, { children: children })] })) })));
});
export default Note;
