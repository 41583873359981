var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Input from '../Input';
import * as S from './styles';
const TextInput = forwardRef(function TextInput(_a, ref) {
    var { className, label, fullWidth, disabled, error, inputRef } = _a, inputProps = __rest(_a, ["className", "label", "fullWidth", "disabled", "error", "inputRef"]);
    return (_jsxs(S.FormControl, Object.assign({ ref: ref, className: className, fullWidth: fullWidth }, { children: [_jsx(S.Label, { children: label }), _jsx(Input, Object.assign({ ref: inputRef, error: error, disabled: disabled, fullWidth: fullWidth }, inputProps)), error && (_jsx(S.FormError, { children: _jsx(S.ErrorMessage, { children: error }) }))] })));
});
export default TextInput;
