import styled from 'styled-components';
export const Group = styled.div `
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${p => p.theme.colors.primaryContainer};
  border-radius: 8px;

  /* All labels except the first */
  & > * + input + label {
    margin-left: 10px;
  }
`;
export const Input = styled.input `
  display: none;

  /* Check radio label styles */
  &[type='radio']:checked + label {
    background: ${p => p.theme.colors.primary};
    color: ${p => p.theme.colors.onPrimary};
  }
`;
export const Label = styled.label `
  font-size: 18px;
  text-align: center;
  padding: 10px;
  flex-grow: 1;
  border-radius: 6px;
  color: ${p => p.theme.colors.onPrimaryContainer};
  flex-grow: 1;
  flex-basis: 0;
  background: ${p => p.theme.colors.primaryContainer};
  margin: 4px;
`;
