import { useContext } from 'react';
import { AppNavigationContext } from './AppNavigationProvider';
function useAppNavigationContext() {
    const context = useContext(AppNavigationContext);
    if (!context) {
        throw new Error('useAppNavigationContext must be used inside a AppNavigationProvider.');
    }
    return context;
}
export default useAppNavigationContext;
