import styled from 'styled-components';
export const FixedNav = styled.nav `
  position: fixed;
  width: 100%;
  z-index: 100;
`;
export const NavSpacer = styled.div `
  height: ${props => props.height}px;
`;
export const HeroNavigationBar = styled.div `
  min-height: 44px;
  /* https://css-tricks.com/the-notch-and-css/ */
  padding-top: env(safe-area-inset-top);
  position: relative;
  display: flex;
  color: ${props => props.theme.colors.neutral100};
  background-image: linear-gradient(
    to bottom,
    rgba(85, 79, 77, 0.6),
    rgba(87, 80, 79, 0)
  );
`;
