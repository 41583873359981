import { Button, Card, Input, Select } from 'pqbc-vas-design-system';
import styled from 'styled-components';

export const MapCard = styled(Card)`
  flex: 1;
`;

export const MethodButton = styled(Button).attrs({
  color: 'secondary',
})`
  margin: 5px 0px;
  width: 100%;
`;

export const RedButton = styled(Button).attrs({
  color: 'primary',
})`
  margin: 5px 0px;
  width: 100%;
`;

export const SaveButton = styled(Button).attrs({
  color: 'tertiary',
})`
  margin: 5px 0px 5px 5px;
  width: fit-content;
`;

export const MethodInput = styled(Input).attrs({})`
  margin: 5px 0px;
  width: 100%;
`;

export const TicketSelect = styled(Select).attrs({})`
  margin: 5px 0px;
  width: 100%;
`;
