import styled from 'styled-components';
import Card, { CardContent } from '../Card';
import { Header3, Paragraph } from '../Typography';
export const DialogCard = styled(Card) `
  margin-left: 4px;
  margin-right: 4px;
`;
export const DialogCardContent = styled(CardContent) `
  padding-bottom: 54px;
`;
export const Title = styled(Header3) `
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.colors.onSurfaceSecondary};
`;
export const Message = styled(Paragraph) `
  margin-top: 10px;
  text-align: center;
  color: ${props => props.theme.colors.onSurface};
`;
export const DialogActions = styled.div `
  width: 100%;
  position: absolute;
  bottom: -28px;
  display: flex;
  justify-content: center;
`;
