import styled from 'styled-components';
import { themed } from '../theme';
const getTransactionTypeColor = themed(props => {
    switch (props.transactionType) {
        case 'deposit':
            return props.theme.colors.onSurfaceTertiary;
        case 'charge':
        default:
            return props.theme.colors.onSurfaceSecondary;
    }
});
export const AmountContainer = styled.div `
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  color: ${getTransactionTypeColor};
`;
export const LabelContainer = styled.div ``;
export const SubLabel = styled.span `
  display: block;
  font-size: 15px;
  color: ${props => props.theme.colors.onSurfaceSecondary};
`;
export const Label = styled.span `
  display: block;
  font-size: 16px;
  color: #3a3634;
  margin-top: 4px;
`;
export const FlexRight = styled.div `
  flex: 1 0 auto;
`;
export const AmountLabel = styled.span `
  margin-right: 4px;
  font-family: ${props => props.theme.typography.label.fontFamily};
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
`;
export const CurrencyLabel = styled.span `
  font-family: ${props => props.theme.typography.label.fontFamily};
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
`;
