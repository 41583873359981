import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, forwardRef } from 'react';
import Hero from '../Hero';
import NavigationBar, { NavigationBarContent } from '../NavigationBar';
import * as S from './styles';
import useAppNavigationContext from './useAppNavigationContext';
import useAppNavigationHeightDetection from './useAppNavigationHeightDetection';
const AppNavigation = forwardRef(function AppNavigation(props, ref) {
    const { options, setOptions } = useAppNavigationContext();
    const { ref: heightRef, height } = useAppNavigationHeightDetection();
    const { title, left, right, extension, backgroundImage } = useMemo(() => {
        return Object.assign(Object.assign({}, props), options);
    }, [props, options]);
    useMemo(() => {
        setOptions(Object.assign(Object.assign({}, options), { height }));
    }, [height]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsxs("div", Object.assign({ ref: ref }, { children: [_jsx(S.FixedNav, { children: _jsxs("div", Object.assign({ ref: heightRef }, { children: [backgroundImage ? (_jsx(S.HeroNavigationBar, { children: _jsxs(Hero, Object.assign({ image: backgroundImage }, { children: [left, _jsx(NavigationBarContent, { children: title }), right] })) })) : (_jsxs(NavigationBar, { children: [left, _jsx(NavigationBarContent, { children: title }), right] })), extension] })) }), _jsx(S.NavSpacer, { height: height })] })));
});
export default AppNavigation;
