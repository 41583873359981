import styled from 'styled-components';
const Header2 = styled.h2 `
  font-family: ${props => props.theme.typography.header2.fontFamily};
  font-size: ${props => props.theme.typography.header2.fontSize};
  font-weight: ${props => props.theme.typography.header2.fontWeight};
  font-style: ${props => props.theme.typography.header2.fontStyle};
  font-stretch: ${props => props.theme.typography.header2.fontStretch};
  line-height: ${props => props.theme.typography.header2.lineHeight};
  letter-spacing: ${props => props.theme.typography.header2.letterSpacing};
  margin-block-start: 0;
  margin-block-end: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
`;
export default Header2;
