import { useEffect, useRef, useState } from 'react';
import { useWindowSize, useUpdateEffect } from 'react-use';
function useAppNavigationHeightDetection() {
    const ref = useRef(null);
    const [height, setHeight] = useState(0);
    const { width } = useWindowSize();
    useUpdateEffect(() => {
        if (ref.current) {
            setHeight(ref.current.clientHeight);
        }
    }, [setHeight]);
    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.clientHeight);
        }
    }, [width]);
    return {
        ref,
        height,
    };
}
export default useAppNavigationHeightDetection;
