var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import ButtonSpinner from './ButtonSpinner';
import * as S from './styles';
const Button = forwardRef(function Button(_a, ref) {
    var { color = 'default', loading, inline, outline, disabled, className, addonBefore, addonAfter, children } = _a, rest = __rest(_a, ["color", "loading", "inline", "outline", "disabled", "className", "addonBefore", "addonAfter", "children"]);
    return (_jsxs(S.Button, Object.assign({ ref: ref, className: className, color: color, disabled: disabled, inline: inline, outline: outline }, rest, { children: [addonBefore, _jsx(S.Label, Object.assign({ color: color, disabled: disabled, inline: inline, outline: outline }, { children: loading ? _jsx(ButtonSpinner, {}) : children })), addonAfter] })));
});
export default Button;
