import styled from 'styled-components';
export const Input = styled.input.attrs({
    type: 'checkbox',
}) `
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colors.outlineVariant};
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 transparent;

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: ${props => props.theme.colors.onSurfaceSoft};
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &:checked {
    box-shadow: inset 20px 0 0 0 ${props => props.theme.colors.tertiary};
    border-color: ${props => props.theme.colors.tertiary};
  }

  &:checked:after {
    background: transparent;
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
  }
`;
