import styled from 'styled-components';
export const ListItemDivider = styled.hr `
  margin-block-start: 0;
  margin-block-end: 0;
  height: 1px;
  color: ${props => props.theme.colors.outlineVariant};
  background-color: ${props => props.theme.colors.outlineVariant};
  border: none;
  margin-left: 24px;
  margin-right: 24px;
`;
export default ListItemDivider;
