import styled, { css } from 'styled-components';
export const AbsoluteFloater = styled.div `
  position: absolute;
  top: ${props => props.overlapMargin * -1}px;
  width: 100%;
  ${p => p.height &&
    css `
      height: ${p.height}px;
      overflow: auto;
    `}
`;
export const FloaterContent = styled.div `
  position: relative;
  width: 100%;
  z-index: 100;
`;
