import styled from 'styled-components';
export const Note = styled.div `
  padding: 10px 14px;
  background-color: ${props => props.theme.colors.surfaceVariant};
  color: ${props => props.theme.colors.onSurfaceVariant};
  border-radius: 8px;
`;
export const Icon = styled.div `
  padding-right: 10px;
  color: inherit;
`;
