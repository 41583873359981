import styled from 'styled-components';
import { Header4 } from '../Typography';
import { IconButton } from '../Button';
import Card from '../Card';
export const Container = styled(Card) `
  border-radius: 0;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: ${props => props.theme.colors.surface};
  width: 100vw;
`;
export const BottomSheetHeaderContainer = styled.div `
  background-color: ${props => props.theme.colors.surface};
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`;
export const BottomSheetHeaderContent = styled.div `
  margin-left: 16px;
  margin-right: 16px;
  height: 64px;
  display: flex;
  align-items: center;
`;
export const BottomSheetTitle = styled(Header4) `
  color: ${props => props.theme.colors.onSurfaceSecondary};
  flex: 1;
`;
export const BottomSheetButton = styled(IconButton) `
  background-color: ${props => props.theme.colors.surfaceVariant};
  color: ${props => props.theme.colors.onSurfaceVariant};
`;
export const BottomSheetSpacer = styled.div `
  height: 15px;
`;
