import styled from 'styled-components';
import { CardContent as DSCardContent } from '../Card';
import Popover from '../Popover';
import { Header3, Paragraph, Link } from '../Typography';
import { ButtonStyles } from '../Button';
export const DialogCard = styled(DSCardContent) `
  width: calc(100vw - 16px);
`;
export const Actions = styled.div `
  position: relative;
  z-index: 99000;
  width: 100%;
  margin-top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > ${ButtonStyles.Button} {
    width: 90%;
  }
`;
export const Title = styled(Header3) `
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.colors.onSurfaceSecondary};
`;
export const Message = styled(Paragraph) `
  margin-top: 8px;
  text-align: center;
  color: ${props => props.theme.colors.onSurface};
  margin-bottom: 24px;
`;
export const Dialog = styled(Popover) `
  height: 100%;
`;
export const ScrollContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 15px 0 15px;
  max-height: 100vh;
`;
export const BottomLink = styled(Link).attrs({ as: 'p' }) `
  position: relative;
  margin: 15px 0;
  color: ${p => p.theme.colors.neutral100};
  text-decoration: underline;
`;
