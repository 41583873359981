var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-undef */
import dsBridge from "dsbridge";
import { isNil } from "lodash";
import { isAndroid } from "./platform";
import NativeMethodNotFoundError from "./NativeMethodNotFoundError";
export function invoke(method, args) {
    return __awaiter(this, void 0, void 0, function* () {
        if (isAndroid()) {
            return invokeAndroid(method, args);
        }
        return invokeiOS(method, args);
    });
}
function invokeiOS(method, args) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (!dsBridge.hasNativeMethod(method)) {
                return reject(new NativeMethodNotFoundError(method));
            }
            try {
                dsBridge.call(method, args, resolve);
            }
            catch (err) {
                return reject(err);
            }
            return undefined;
        });
    });
}
function invokeAndroid(method, args) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (!NativeBridge || !NativeBridge[method]) {
                return reject(new NativeMethodNotFoundError(method));
            }
            try {
                let response;
                // We have to explicitly check if there are arguments.
                // The native android invocation doesn't like it when we pass null or undefined if no arguments are expected.
                if (isNil(args)) {
                    response = NativeBridge[method]();
                }
                else {
                    response = NativeBridge[method](args);
                }
                return resolve(response);
            }
            catch (err) {
                return reject(err);
            }
        });
    });
}
export default invoke;
