import { Icon } from 'leaflet';

import markericon from '@/assets/icons/map-marker.svg';

const markerIcon = new Icon({
  iconUrl: markericon,
  iconAnchor: [15, 15],
  popupAnchor: [0, -15],
  iconSize: [30, 30],
});

export { markerIcon };
