import styled from 'styled-components';
const Paragraph = styled.p `
  font-family: ${props => props.theme.typography.paragraph.fontFamily};
  font-size: ${props => props.theme.typography.paragraph.fontSize};
  font-weight: ${props => props.theme.typography.paragraph.fontWeight};
  font-style: ${props => props.theme.typography.paragraph.fontStyle};
  font-stretch: ${props => props.theme.typography.paragraph.fontStretch};
  line-height: ${props => props.theme.typography.paragraph.lineHeight};
  letter-spacing: ${props => props.theme.typography.paragraph.letterSpacing};
  margin-block-start: 0;
  margin-block-end: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
`;
export default Paragraph;
