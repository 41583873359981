var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { useSegmentedControlContext } from './Context';
import * as S from './styles';
const Item = forwardRef(function Item(_a, ref) {
    var _b, _c;
    var { className, children, value } = _a, rest = __rest(_a, ["className", "children", "value"]);
    const groupContext = useSegmentedControlContext();
    const isChecked = value === groupContext.value;
    return (_jsxs(_Fragment, { children: [_jsx(S.Input, Object.assign({ ref: ref, id: `${(_b = groupContext.name) !== null && _b !== void 0 ? _b : ''}-${value}`, type: 'radio', name: groupContext.name, value: value, onChange: groupContext.onChange, checked: isChecked, className: className }, rest)), _jsx(S.Label, Object.assign({ htmlFor: `${(_c = groupContext.name) !== null && _c !== void 0 ? _c : ''}-${value}` }, { children: children }))] }));
});
export default Item;
