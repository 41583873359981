import { Dialog, Flex, Button as DSButton } from 'pqbc-vas-design-system';
import styled from 'styled-components';

export const DialogMessage = styled(Dialog.Message)`
  white-space: pre-line;
`;

export const ButtonGroup = styled(Flex)`
  /* All children except first */
  & > * + * {
    margin-top: 8px;
  }
`;

export const Button = styled(DSButton)`
  width: 100%;
`;
