import dsBridge from "dsbridge";
import { isAndroid } from "./platform";
export function register(methodName, handler) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (isAndroid() || window.chrome) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window[methodName] = handler;
    }
    else {
        dsBridge.register(methodName, handler);
    }
}
export default register;
