import styled from 'styled-components';
const Link = styled.span `
  font-family: ${props => props.theme.typography.link.fontFamily};
  font-size: ${props => props.theme.typography.link.fontSize};
  font-weight: ${props => props.theme.typography.link.fontWeight};
  font-style: ${props => props.theme.typography.link.fontStyle};
  font-stretch: ${props => props.theme.typography.link.fontStretch};
  line-height: ${props => props.theme.typography.link.lineHeight};
  letter-spacing: ${props => props.theme.typography.link.letterSpacing};
  text-decoration: underline;
  cursor: pointer;
`;
export default Link;
