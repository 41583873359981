/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
import { themed } from '../theme/helpers';
const bgColor = themed(({ color, theme, inline, outline }) => {
    if (inline) {
        return theme.colors.neutral200;
    }
    if (outline) {
        return 'transparent';
    }
    switch (color) {
        case 'default':
            return theme.colors.neutral500;
        case 'primary':
            return theme.colors.primary;
        case 'secondary':
            return theme.colors.secondary;
        case 'tertiary':
            return theme.colors.tertiary;
        case 'success':
            return theme.colors.success500;
        case 'error':
            return theme.colors.error;
        default:
            return 'inherit';
    }
});
const activeBgColor = themed(({ color, theme, inline, outline }) => {
    if (inline) {
        return theme.colors.neutral200;
    }
    if (outline) {
        switch (color) {
            case 'default':
                return theme.colors.neutral200;
            case 'primary':
                return theme.colors.outlinePrimarySoft;
            case 'secondary':
                return theme.colors.outlineSecondarySoft;
            case 'error':
                return theme.colors.outlineErrorSoft;
            default:
                return theme.colors.neutral200;
        }
    }
    switch (color) {
        case 'default':
            return theme.colors.neutral600;
        case 'primary':
            return theme.colors.primary;
        case 'secondary':
            return theme.colors.secondary;
        case 'tertiary':
            return theme.colors.tertiary;
        case 'success':
            return theme.colors.success600;
        case 'error':
            return theme.colors.error;
        default:
            return theme.colors.neutral300;
    }
});
const disabledBgColor = themed(({ color, theme, inline }) => {
    if (inline) {
        return theme.colors.neutral200;
    }
    switch (color) {
        case 'default':
        case 'primary':
        case 'secondary':
        case 'tertiary':
        case 'success':
        case 'error':
            return theme.colors.surfaceInactive;
        default:
            return 'inherit';
    }
});
const textColor = themed(({ color, theme, inline, outline }) => {
    if (inline) {
        return theme.colors.secondary500;
    }
    if (outline) {
        switch (color) {
            case 'primary':
                return theme.colors.onSurfacePrimary;
            case 'secondary':
                return theme.colors.onSurfaceSecondary;
            case 'tertiary':
                return theme.colors.onSurfaceTertiary;
            case 'success':
                return theme.colors.success600;
            case 'error':
                return theme.colors.onSurfaceError;
            case 'default':
            default:
                return theme.colors.neutral600;
        }
    }
    switch (color) {
        case 'primary':
            return theme.colors.onPrimary;
        case 'secondary':
            return theme.colors.onSecondary;
        case 'tertiary':
            return theme.colors.onTertiary;
        case 'default':
        case 'success':
            return theme.colors.neutral100;
        case 'error':
            return theme.colors.onError;
        default:
            return theme.colors.neutral500;
    }
});
const iconColor = themed(({ color, theme }) => {
    switch (color) {
        case 'default':
            return theme.colors.neutral500;
        case 'primary':
            return theme.colors.primary;
        case 'secondary':
            return theme.colors.secondary;
        case 'tertiary':
            return theme.colors.tertiary;
        case 'success':
            return theme.colors.success500;
        case 'error':
            return theme.colors.error;
        default:
            return 'inherit';
    }
});
const disabledButtonCss = css `
  && {
    background-color: ${disabledBgColor};

    &:active {
      background-color: ${disabledBgColor};
    }
  }
`;
export const Button = styled.button `
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 350px;
  max-width: 100%;
  height: 56px;
  border-style: none;
  border-radius: 4px;
  padding: 16px 18px;
  background-color: ${bgColor};
  transition: background-color 200ms ease-in;
  &:active {
    background-color: ${activeBgColor};
  }

  &:active:disabled {
    border-style: none;
    -webkit-tap-highlight-color: transparent;
  }

  &:focus {
    outline: none;
  }

  ${props => (props.disabled ? disabledButtonCss : null)}

  ${p => p.inline &&
    css `
      border: 1px solid ${props => props.theme.colors.neutral300};
      &:active {
        border-color: ${props => props.theme.colors.neutral400};
      }
      &:active:disabled {
        border: 1px solid ${props => props.theme.colors.neutral300};
      }
    `}

  ${p => p.outline &&
    css `
      border: 1px solid ${activeBgColor};
    `}
`;
export const Label = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.disabled ? props.theme.colors.onSurfaceInactive : textColor(props)};
  font-family: ${props => props.theme.typography.button.fontFamily};
  font-size: ${props => props.theme.typography.button.fontSize};
  font-weight: ${props => props.theme.typography.button.fontWeight};
  font-style: ${props => props.theme.typography.button.fontStyle};
  font-stretch: ${props => props.theme.typography.button.fontStretch};
  line-height: ${props => props.theme.typography.button.lineHeight};
  letter-spacing: ${props => props.theme.typography.button.letterSpacing};
`;
export const IconButton = styled.button `
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  border-style: none;
  background-color: inherit;
  color: ${props => props.disabled ? props.theme.colors.neutral300 : iconColor(props)};

  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &:active:disabled {
    border-style: none;
  }

  &:focus {
    outline: none;
  }

  ${props => (props.disabled ? disabledButtonCss : null)}
`;
export const ButtonSpinnerContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
`;
