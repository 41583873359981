var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Content, Overlay } from '@radix-ui/react-dialog';
import { forwardRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
export const ScrollBarFixer = createGlobalStyle `
  body {
      margin-right: 0px !important
  }
`;
export const PopoverContent = styled.div `
  position: relative;
`;
// new Popover
export const PopoverOverlay = styled(Overlay) `
  background-color: hsla(0, 0%, 0%, 0.7);
  position: fixed;
  inset: 0;
  z-index: 1200;
`;
export const PopoverContentContainer = styled(forwardRef(function ForwardRefContent(_a, forwardedRef) {
    var { alignContent: _ } = _a, props = __rest(_a, ["alignContent"]);
    return _jsx(Content, Object.assign({}, props, { ref: forwardedRef }));
})) `
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 1201;
  align-items: ${(props) => props.alignContent === 'center' ? 'center' : 'flex-end'};
`;
