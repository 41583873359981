import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './theme';
function ThemeProvider({ theme: localTheme, children }) {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const [dark, setDark] = useState(mediaQuery.matches);
    mediaQuery.addEventListener('change', e => setDark(e.matches));
    const theme = useMemo(() => {
        return localTheme || (dark ? darkTheme : lightTheme);
    }, [localTheme, dark]);
    return (_jsx(StyledComponentsThemeProvider, Object.assign({ theme: theme }, { children: children })));
}
export default ThemeProvider;
