import styled from 'styled-components';
const ifProp = (prop) => (props) => {
    const { [prop]: property } = props;
    if (property) {
        return {
            [prop]: property,
        };
    }
    return null;
};
const Flex = styled.div `
  display: flex;
  ${ifProp('justifyContent')};
  ${ifProp('alignItems')};
  ${ifProp('flexDirection')};
  ${ifProp('flexWrap')};
  ${ifProp('flex')};
`;
export default Flex;
