import { jsx as _jsx } from "react/jsx-runtime";
import Lottie from 'lottie-react';
import { forwardRef } from 'react';
import animationData from './lottie_loader.json';
import * as S from './styles';
const SPINNER_OPTIONS = {
    loop: true,
    autoplay: true,
    animationData,
};
const getHeight = (size) => {
    switch (size) {
        case 'small':
            return '20px';
        case 'large':
            return '65px';
        default:
            return '35px';
    }
};
const Spinner = forwardRef(function Spinner({ size = 'medium', className }, ref) {
    const height = getHeight(size);
    return (_jsx(S.Container, Object.assign({ className: className, ref: ref }, { children: _jsx(Lottie, Object.assign({}, SPINNER_OPTIONS, { style: { flex: 1, height } })) })));
});
export default Spinner;
