var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as S from './styles';
const ListItem = forwardRef(function ListItem(_a, ref) {
    var { className, children, link = false } = _a, rest = __rest(_a, ["className", "children", "link"]);
    return (_jsxs(S.Item, Object.assign({ ref: ref, className: className }, rest, { children: [children, link ? (_jsx(S.ItemIconContainer, { children: _jsx(S.ChevronRight, {}) })) : null] })));
});
export default ListItem;
