/* eslint-disable no-console */
import { NATIVE_METHODS } from "./constants";
function createMock(methodName, callback) {
    return function mock(...args) {
        console.log(`VASNativeBridge.${methodName}, arguments:`, JSON.stringify([...args]));
        if (callback) {
            return callback(...args);
        }
        return undefined;
    };
}
export function injectNativeBridgeMock({ language, appVersion }) {
    if (typeof window !== "undefined") {
        window.NativeBridge = {};
        window.NativeBridge[NATIVE_METHODS.START_PAYCONIQ_PAYMENT_FLOW] =
            createMock(NATIVE_METHODS.START_PAYCONIQ_PAYMENT_FLOW);
        window.NativeBridge[NATIVE_METHODS.CLOSE_WEBVIEW] = createMock(NATIVE_METHODS.CLOSE_WEBVIEW);
        window.NativeBridge[NATIVE_METHODS.OPEN_URL] = createMock(NATIVE_METHODS.OPEN_URL);
        window.NativeBridge[NATIVE_METHODS.START_OAUTH] = createMock(NATIVE_METHODS.START_OAUTH);
        window.NativeBridge[NATIVE_METHODS.GET_LANGUAGE] = createMock(NATIVE_METHODS.GET_LANGUAGE, () => language);
        window.NativeBridge[NATIVE_METHODS.GET_TOKEN] = createMock(NATIVE_METHODS.GET_TOKEN);
        window.NativeBridge[NATIVE_METHODS.GET_APP_VERSION] = createMock(NATIVE_METHODS.GET_APP_VERSION, () => appVersion);
        window.NativeBridge[NATIVE_METHODS.GET_APP_INFO] = createMock(NATIVE_METHODS.GET_APP_INFO, () => JSON.stringify(Object.assign(Object.assign({}, (appVersion ? { appVersion } : {})), (language ? { language } : {}))));
        window.NativeBridge[NATIVE_METHODS.GET_TRANSACTION_REPORT] = createMock(NATIVE_METHODS.GET_TRANSACTION_REPORT);
        window.NativeBridge[NATIVE_METHODS.SAVE_TOKEN] = createMock(NATIVE_METHODS.SAVE_TOKEN);
        window.NativeBridge[NATIVE_METHODS.REMOVE_TOKEN] = createMock(NATIVE_METHODS.REMOVE_TOKEN);
        window.NativeBridge[NATIVE_METHODS.TRACK_EVENT] = createMock(NATIVE_METHODS.TRACK_EVENT);
        window.NativeBridge[NATIVE_METHODS.SHARE_CONTENT] = createMock(NATIVE_METHODS.SHARE_CONTENT);
        window.NativeBridge[NATIVE_METHODS.SYNC_TICKETS] = createMock(NATIVE_METHODS.SYNC_TICKETS);
        window.NativeBridge[NATIVE_METHODS.OPEN_TICKET] = createMock(NATIVE_METHODS.OPEN_TICKET);
        window.NativeBridge[NATIVE_METHODS.DELETE_ALL_TICKETS] = createMock(NATIVE_METHODS.DELETE_ALL_TICKETS);
        window.NativeBridge[NATIVE_METHODS.MARK_IN_USE] = createMock(NATIVE_METHODS.MARK_IN_USE);
    }
}
export default injectNativeBridgeMock;
