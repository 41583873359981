import styled from 'styled-components';
const Label = styled.label `
  font-family: ${props => props.theme.typography.label.fontFamily};
  font-size: ${props => props.theme.typography.label.fontSize};
  font-weight: ${props => props.theme.typography.label.fontWeight};
  font-style: ${props => props.theme.typography.label.fontStyle};
  font-stretch: ${props => props.theme.typography.label.fontStretch};
  line-height: ${props => props.theme.typography.label.lineHeight};
  letter-spacing: ${props => props.theme.typography.label.letterSpacing};
  margin-block-start: 0;
  margin-block-end: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
`;
export default Label;
