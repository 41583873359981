import styled from 'styled-components';
export const Svg = styled.svg `
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: 24px;
  vertical-align: top;
`;
export default Svg;
