import styled from 'styled-components';
const Header1 = styled.h1 `
  font-family: ${props => props.theme.typography.header1.fontFamily};
  font-size: ${props => props.theme.typography.header1.fontSize};
  font-weight: ${props => props.theme.typography.header1.fontWeight};
  font-style: ${props => props.theme.typography.header1.fontStyle};
  font-stretch: ${props => props.theme.typography.header1.fontStretch};
  line-height: ${props => props.theme.typography.header1.lineHeight};
  letter-spacing: ${props => props.theme.typography.header1.letterSpacing};
  margin-block-start: 0;
  margin-block-end: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
`;
export default Header1;
