var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as S from './styles';
const Select = forwardRef(function Select(_a, ref) {
    var { prefix, suffix, fullWidth, onBlur, children } = _a, rest = __rest(_a, ["prefix", "suffix", "fullWidth", "onBlur", "children"]);
    const select = (_jsx(S.Select, Object.assign({ ref: ref, onBlur: (e) => {
            window.scrollTo(0, 0);
            if (onBlur) {
                onBlur(e);
            }
        } }, rest, { children: children })));
    if (!prefix && !suffix) {
        return select;
    }
    return (_jsxs(S.Affix, Object.assign({ fullWidth: fullWidth }, { children: [(prefix && _jsx(S.Adfix, Object.assign({ left: 8 }, { children: prefix }))) || null, select, (suffix && _jsx(S.Adfix, Object.assign({ right: 8 }, { children: suffix }))) || null] })));
});
export default Select;
