import { Routes, Route, Navigate } from 'react-router-dom';

import * as S from './styles';

import { Overview } from '@/screens';

const AppRoutes = () => (
  <S.Main>
    <Routes>
      <Route path='/' element={<Overview />} />
      <Route path='/*' element={<Navigate to='/' />} />
    </Routes>
  </S.Main>
);

export default AppRoutes;
