var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as S from './styles';
const SvgIcon = forwardRef(function SvgIcon(_a, ref) {
    var { titleAccess, htmlColor, viewBox = '0 0 24 24', children } = _a, other = __rest(_a, ["titleAccess", "htmlColor", "viewBox", "children"]);
    return (_jsxs(S.Svg, Object.assign({ ref: ref, focusable: 'false', viewBox: viewBox, color: htmlColor, "aria-hidden": titleAccess ? 'false' : 'true', role: titleAccess ? 'img' : 'presentation' }, other, { children: [children, titleAccess ? _jsx("title", { children: titleAccess }) : null] })));
});
export default SvgIcon;
