import styled from 'styled-components';
export const Card = styled.div `
  position: relative;
  border-radius: 4px;
  box-shadow: 0 3px 5px 0 rgba(122, 115, 113, 0.12);
  background-color: ${props => props.theme.colors.surface};
  color: ${props => props.theme.colors.onSurface};
`;
export const CardContent = styled.div `
  padding: 24px 16px;
`;
export const CardImage = styled.img `
  width: 100%;
  color: red;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  object-fit: ${props => (props.objectFit ? props.objectFit : 'none')};
`;
