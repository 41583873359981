import styled from 'styled-components';
import { themed } from '../theme';
export const textareaBorderColor = themed(({ error, theme }) => error ? theme.colors.outlineError : theme.colors.outline);
export const Textarea = styled.textarea `
  flex: 1;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: ${textareaBorderColor};
  padding: 12px 16px;
  color: ${props => props.disabled
    ? props.theme.colors.onSurfaceVariant
    : props.theme.colors.onSurfaceSecondary};
  background-color: ${props => props.disabled ? props.theme.colors.outlineVariant : 'inherit'};
  font-family: ${props => props.theme.typography.input.fontFamily};
  font-size: ${props => props.theme.typography.input.fontSize};
  font-weight: ${props => props.theme.typography.input.fontWeight};
  font-style: ${props => props.theme.typography.input.fontStyle};
  font-stretch: ${props => props.theme.typography.input.fontStretch};
  line-height: ${props => props.theme.typography.input.lineHeight};
  letter-spacing: ${props => props.theme.typography.input.letterSpacing};
  ${p => p.fullWidth && 'width: 100%'};

  &:focus {
    outline-offset: none;
    outline: none;
    border-color: ${props => props.theme.colors.outlineSecondary};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.colors.onSurfaceSoft};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${props => props.theme.colors.onSurfaceSoft};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${props => props.theme.colors.onSurfaceSoft};
  }

  /* Remove inner shadow on ios */
  -webkit-appearance: none;
`;
