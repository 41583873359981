const colors = {
    error200: '#F2C8CE',
    error500: '#E20944',
    error600: '#C9083C',
    neutral100: '#FFFFFF',
    neutral200: '#FBF8F5',
    neutral300: '#E7E4DD',
    neutral400: '#BDB8B1',
    neutral500: '#97928D',
    neutral600: '#7A7371',
    neutral700: '#5A5451',
    neutral800: '#3A3634',
    primary100: '#FFECF2',
    primary200: '#FF89B0',
    primary300: '#FF6B9D',
    primary400: '#FF5991',
    primary500: '#FF4785',
    primary600: '#E64078',
    primary700: '#CC396A',
    primary800: '#B3325D',
    primary900: '#992B50',
    secondary100: '#E7EBF6',
    secondary200: '#B9C3DD',
    secondary300: '#838FB6',
    secondary400: '#3C4E84',
    secondary500: '#0B2265',
    secondary600: '#0A1F5B',
    secondary700: '#091B51',
    secondary800: '#081847',
    secondary900: '#07143D',
    success500: '#00CB75',
    success600: '#00B769',
    tertiary50: '#E1FBE5',
    tertiary100: '#AEF8BA',
    tertiary200: '#86E996',
    tertiary300: '#70DC86',
    tertiary400: '#06D179',
    tertiary500: '#00CB75',
    tertiary600: '#00B769',
    tertiary700: '#00A25E',
    tertiary800: '#008E52',
    tertiary900: '#007A46',
    destructive100: '#FFF0F2',
    destructive200: '#F2C8CE',
    destructive300: '#FBB8C1',
    destructive500: '#CF0833',
    destructive600: '#A7082B',
};
export const defaultColors = colors;
export const lightModeColors = Object.assign(Object.assign({}, colors), { 
    // primary
    primary: colors.primary500, onPrimary: colors.neutral100, primaryContainer: colors.primary100, onPrimaryContainer: colors.primary500, 
    // secondary
    secondary: colors.secondary500, onSecondary: colors.neutral100, secondaryContainer: colors.secondary100, onSecondaryContainer: colors.secondary500, 
    // tertiary
    tertiary: colors.tertiary500, onTertiary: colors.neutral100, tertiaryContainer: colors.tertiary50, onTertiaryContainer: colors.tertiary700, tertiarySelected: colors.tertiary700, 
    // error
    error: colors.destructive500, onError: colors.neutral100, errorContainer: colors.destructive100, onErrorContainer: colors.destructive500, 
    // background
    background: colors.neutral200, onBackground: colors.neutral700, onBackgroundSecondary: colors.secondary500, 
    // surface
    surface: colors.neutral100, onSurface: colors.neutral700, onSurfacePrimary: colors.primary500, onSurfaceSecondary: colors.secondary500, onSurfaceTertiary: colors.tertiary700, onSurfaceError: colors.destructive500, onSurfaceSoft: colors.neutral400, 
    // surface variant
    surfaceVariant: colors.neutral200, onSurfaceVariant: colors.neutral700, 
    // surface inactive
    surfaceInactive: colors.neutral300, onSurfaceInactive: colors.neutral400, 
    // inverse surface
    inverseSurface: 'rgba(55, 55, 58, 0.9)', onInverseSurface: colors.neutral100, onInverseSurfacePrimary: colors.primary500, 
    // outline
    outline: colors.neutral400, outlineVariant: colors.neutral300, outlineSecondary: colors.secondary500, outlineSecondarySoft: colors.secondary200, outlineError: colors.destructive500, outlineErrorSoft: colors.destructive200, outlineTransparent: 'rgba(0, 0, 0, 0.1)', outlinePrimarySoft: colors.primary200 });
export const darkModeColors = Object.assign(Object.assign({}, colors), { 
    // primary
    primary: '#0D0609', onPrimary: '#FFFFFF', primaryContainer: '#330E1B', onPrimaryContainer: '#FF4785', 
    // secondary
    secondary: '#FFFFFF', onSecondary: '#030102', secondaryContainer: '#201619', onSecondaryContainer: '#FFB1C4', 
    // tertiary
    tertiary: '#029C35', onTertiary: '#FFFFFF', tertiaryContainer: '#072710', onTertiaryContainer: '#07C83D', tertiarySelected: '#027733', 
    // error
    error: '#BD0E34', onError: '#FFFFFF', errorContainer: '#200F0F', onErrorContainer: '#FF444F', 
    // background
    background: '#0D0609', onBackground: '#A5989C', onBackgroundSecondary: '#FFFFFF', 
    // surface
    surface: '#181113', onSurface: '#A5989C', onSurfacePrimary: '#FF4785', onSurfaceSecondary: '#F5EAEE', onSurfaceTertiary: '#20C84F', onSurfaceError: '#D0163E', onSurfaceSoft: '#4A343B', 
    // surface variant
    surfaceVariant: '#21171A', onSurfaceVariant: '#F2E4E9', 
    // surface inactive
    surfaceInactive: '#181113', onSurfaceInactive: '#3B292F', 
    // inverse surface
    inverseSurface: 'rgba(0, 0, 0, 0.6)', onInverseSurface: '#FFFFFF', onInverseSurfacePrimary: '#FF4785', 
    // outline
    outline: '#4A343B', outlineVariant: '#24191D', outlineSecondary: '#CBA6B3', outlineSecondarySoft: '#2E1E24', outlineError: '#BB0932', outlineErrorSoft: '#2C1313', outlineTransparent: 'rgba(255, 255, 255, 0.1)', outlinePrimarySoft: '#521026' });
export default defaultColors;
