import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as S from './styles';
const Input = forwardRef(function Input(props, ref) {
    return (_jsx(S.Textarea, Object.assign({ ref: ref }, props, { onBlur: (e) => {
            window.scrollTo(0, 0);
            if (props.onBlur) {
                props.onBlur(e);
            }
        } })));
});
export default Input;
