import qs from "qs";
const PAYCONIQ_BASE_URL = "https://payconiq.com/t/1";
export function createPayconiqReceiptAndTopupUrl({ productProfileId, reference, description, amount, }) {
    const queryStringOptions = {
        A: amount,
        D: description,
        R: reference,
    };
    const queryString = qs.stringify(queryStringOptions, {
        encode: true,
        skipNulls: true,
    });
    return `${PAYCONIQ_BASE_URL}/${productProfileId}?${queryString}`;
}
export default createPayconiqReceiptAndTopupUrl;
