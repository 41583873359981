import styled, { css } from 'styled-components';
import { ArrowRight16Icon } from '../icons';
import { Label, ParagraphSmall } from '../Typography';
export const Item = styled.div `
  min-height: 72px;
  padding: 24px 16px;
  display: flex;
  color: ${props => props.theme.colors.onSurfaceSecondary};
`;
export const ItemLabel = styled(Label) `
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;
export const ItemSubtitle = styled(ParagraphSmall) `
  font-size: 15px;
  line-height: 20px;
  margin-top: 5px;
  color: ${props => props.theme.colors.onSurface};
`;
export const ItemIconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.left
    ? css `
          margin-right: 16px;
        `
    : css `
          margin-left: auto;
          padding-left: 16px;
        `};
`;
export const ChevronRight = styled(ArrowRight16Icon) `
  stroke: ${props => props.theme.colors.onSurfaceSoft};
  height: 16px;
`;
