var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { SegmentedControlContext } from './Context';
import * as S from './styles';
const Group = forwardRef(function Card(_a, ref) {
    var { className, children, value, onChange, name } = _a, rest = __rest(_a, ["className", "children", "value", "onChange", "name"]);
    return (_jsx(SegmentedControlContext.Provider, Object.assign({ value: { value, onChange, name } }, { children: _jsx(S.Group, Object.assign({ ref: ref, className: className }, rest, { children: children })) })));
});
export default Group;
