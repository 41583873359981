import styled from 'styled-components';
import { ParagraphSmall } from '../Typography';
export const FormControl = styled.div `
  width: ${props => (props.fullWidth ? '100%' : '295px')};
  display: flex;
  flex-wrap: wrap;
`;
export const Label = styled.label `
  width: 100%;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.onSurface};
  font-family: ${props => props.theme.typography.label.fontFamily};
  font-size: ${props => props.theme.typography.label.fontSize};
  font-weight: ${props => props.theme.typography.label.fontWeight};
  font-style: ${props => props.theme.typography.label.fontStyle};
  font-stretch: ${props => props.theme.typography.label.fontStretch};
  line-height: ${props => props.theme.typography.label.lineHeight};
  letter-spacing: ${props => props.theme.typography.label.letterSpacing};
`;
export const FormError = styled.div `
  width: 100%;
  margin-top: 8px;
`;
export const ErrorMessage = styled(ParagraphSmall).attrs({
    as: 'span',
}) `
  color: ${props => props.theme.colors.error};
`;
