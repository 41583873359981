import styled from 'styled-components';
import Button from '../Button';
import Input from '../Input';
import { Label } from '../Typography';
export const FileInput = styled(Input) `
  display: none;
`;
export const AttachmentPickerButton = styled(Button) `
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 0;
  width: 100%;
  background: ${p => p.theme.colors.neutral100};
`;
export const FileInputLabel = styled(Label) `
  font-size: ${p => p.theme.typography.link.fontSize};
  font-weight: ${p => p.theme.typography.link.fontWeight};
  line-height: ${p => p.theme.typography.link.lineHeight};
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
