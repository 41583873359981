export const NATIVE_METHODS = {
    START_PAYCONIQ_PAYMENT_FLOW: "startPayment",
    CLOSE_WEBVIEW: "close",
    OPEN_URL: "open",
    OPEN_EXTERNAL_URL: "openExternal",
    GET_APP_INFO: "getAppInfo",
    GET_LANGUAGE: "getLanguage",
    GET_TRANSACTION_REPORT: "getTransactionReport",
    TRACK_EVENT: "trackEvent",
    SHARE_CONTENT: "shareContent",
    GET_TOKEN: "getToken",
    SAVE_TOKEN: "saveToken",
    REMOVE_TOKEN: "removeToken",
    GET_APP_VERSION: "getAppVersion",
    START_OAUTH: "startAuthorizationCodeGrant",
    SYNC_TICKETS: "syncTickets",
    OPEN_TICKET: "openTicket",
    DELETE_ALL_TICKETS: "deleteAllTickets",
    MARK_IN_USE: "markInUse",
};
export const NATIVE_CALLBACKS = {
    PAYMENT_FINISHED: "paymentFinished",
    START_DEEP_LINK: "startDeepLink",
    OAUTH_FINISHED: "oauthFinished",
};
