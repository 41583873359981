import { Dialog, Card, PopoverContent } from 'pqbc-vas-design-system';
import type React from 'react';

import * as S from './styles';

interface Props {
  code: string;
  city: string;
  cost: number;
  open: boolean;
  onClose: () => void;
}

const InfoDialog: React.FC<Props> = ({ open, code, city, cost, onClose }) => {
  return (
    <Dialog open={open} onDismiss={onClose}>
      <PopoverContent>
        <Card>
          <Dialog.Content>
            <Dialog.Title>{city}</Dialog.Title>
            <S.DialogMessage>
              Code: {code}
              <br />
              Max cost: €{cost / 100}
            </S.DialogMessage>
            <S.ButtonGroup alignItems='center' flexDirection='column'>
              <S.Button color='secondary' outline onClick={onClose}>
                Close
              </S.Button>
            </S.ButtonGroup>
          </Dialog.Content>
        </Card>
      </PopoverContent>
    </Dialog>
  );
};

export default InfoDialog;
