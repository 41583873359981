import styled, { css } from 'styled-components';
import { themed } from '../theme';
export const selectBorderColor = themed(({ error, theme }) => error ? theme.colors.outlineError : theme.colors.outline);
export const Select = styled.select `
  flex: 1;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: ${selectBorderColor};
  padding: 12px 16px;
  color: ${props => props.disabled
    ? props.theme.colors.onSurfaceVariant
    : props.theme.colors.onSurfaceSecondary};
  background-color: ${props => props.disabled ? props.theme.colors.surfaceInactive : 'inherit'};
  font-family: ${props => props.theme.typography.input.fontFamily};
  font-size: ${props => props.theme.typography.input.fontSize};
  font-weight: ${props => props.theme.typography.input.fontWeight};
  font-style: ${props => props.theme.typography.input.fontStyle};
  font-stretch: ${props => props.theme.typography.input.fontStretch};
  line-height: ${props => props.theme.typography.input.lineHeight};
  letter-spacing: ${props => props.theme.typography.input.letterSpacing};

  &:focus {
    outline-offset: none;
    outline: none;
    border-color: ${props => props.theme.colors.outlineSecondary};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.colors.onSurfaceSoft};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${props => props.theme.colors.onSurfaceSoft};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${props => props.theme.colors.onSurfaceSoft};
  }

  /* Remove inner shadow on ios */
  -webkit-appearance: none;
`;
export const Adfix = styled.span `
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  line-height: 0;
  left: ${p => p.left && `${p.left}px`};
  right: ${p => p.right && `${p.right}px`};
`;
export const Affix = styled.span `
  line-height: 1.5;
  position: relative;
  display: inline-block;
  text-align: start;
  ${p => p.fullWidth &&
    css `
      display: flex;
      width: 100%;
    `};
  ${Select} {
    position: relative;
    text-align: inherit;
  }
  /* prettier-ignore */
  ${Select}:not(:first-child) {
    padding-left: 40px;
  }
  /* prettier-ignore */
  ${Select}:not(:last-child) {
    padding-right: 40px;
  }
`;
