var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as S from './styles';
const SectionHeader = forwardRef(function SectionHeader(_a, ref) {
    var { className, children, as = 'h2' } = _a, rest = __rest(_a, ["className", "children", "as"]);
    return (_jsx(S.Container, Object.assign({ ref: ref, className: className }, rest, { children: _jsx(S.Heading, Object.assign({ as: as }, { children: children })) })));
});
export default SectionHeader;
