var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, isValidElement, Children, forwardRef } from 'react';
import * as S from './styles';
const FormItem = forwardRef(function FormItem(_a, ref) {
    var { className, label, fullWidth, error, children, htmlFor } = _a, rest = __rest(_a, ["className", "label", "fullWidth", "error", "children", "htmlFor"]);
    return (_jsxs(S.FormControl, Object.assign({ ref: ref, className: className, fullWidth: fullWidth }, rest, { children: [_jsx(S.Label, Object.assign({ fullWidth: fullWidth, htmlFor: htmlFor }, { children: label })), Children.map(children, child => isValidElement(child) &&
                // eslint-disable-next-line
                // @ts-ignore
                cloneElement(child, { error, fullWidth })), error && (_jsx(S.FormError, { children: _jsx(S.ErrorMessage, { children: error }) }))] })));
});
export default FormItem;
