import styled from 'styled-components';
export const Hero = styled.div `
  position: relative;
  display: flex;
  flex: 1;
`;
export const Content = styled.div `
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  display: flex;
  z-index: 100;
  padding-left: 12px;
  padding-right: 12px;
`;
