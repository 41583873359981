/**
 * This file has been auto-generared by Hubble post processing.
 * Please refrain from making changes to this file.
 */
export const defaultTypography = {
    button: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '18px',
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.11,
        letterSpacing: 'normal',
    },
    header1: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '30px',
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.07,
        letterSpacing: 'normal',
    },
    header2: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '22px',
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.09,
        letterSpacing: 'normal',
    },
    header3: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '20px',
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.2,
        letterSpacing: 'normal',
    },
    header4: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '18px',
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.22,
        letterSpacing: 'normal',
    },
    input: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '18px',
        fontWeight: 400,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
    },
    label: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '15px',
        fontWeight: 400,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
    },
    link: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '16px',
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
    },
    paragraph: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.38,
        letterSpacing: 'normal',
    },
    paragraphSmall: {
        fontFamily: '"Sofia Pro", "Helvetica", "Arial", sans-serif',
        fontSize: '15px',
        fontWeight: 400,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.6,
        letterSpacing: 'normal',
    },
};
export default defaultTypography;
