import styled, { css } from 'styled-components';
export const Header = styled.header `
  padding-top: 8px;
  @supports (padding: max(0px)) {
    padding-top: max(8px, env(safe-area-inset-top));
  }
  background-color: ${p => p.theme.colors.primary};
  color: ${props => props.theme.colors.onPrimary};
  padding-bottom: var(--header-offset);
  margin-bottom: 8px;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  ${p => p.backgroundImage &&
    css `
      background: linear-gradient(
          to bottom,
          rgba(85, 79, 77, 0.6),
          rgba(87, 80, 79, 0)
        ),
        url(${p.backgroundImage}) center / cover no-repeat;
      align-items: flex-start;
      min-height: 200px;
    `}
`;
export const Wrapper = styled.div `
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  min-height: 40px;
`;
export const Title = styled.div `
  flex: 1;
  text-align: center;
  width: 100%;
  position: absolute;
  z-index: 1;
`;
export const Left = styled.div `
  position: relative;
  z-index: 2;
`;
export const Right = styled.div `
  position: relative;
  z-index: 2;
`;
export const Extension = styled.div `
  width: 100%;
`;
