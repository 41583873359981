import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import SofiaProBold from '../../assets/fonts/sofiapro_bold.woff';
import SofiaProMedium from '../../assets/fonts/sofiapro_medium.woff';
import SofiaProRegular from '../../assets/fonts/sofiapro_regular.woff';

const GlobalStyle = createGlobalStyle`
  ${normalize};

  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --header-offset: 24px;
  }

  html {
    box-sizing: border-box;
  }

  body {
    padding: 0 !important;
  }
  
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  @font-face {
    font-family: "Sofia Pro";
    src: url(${SofiaProRegular}) format('woff');
  }
  @font-face {
    font-family: "Sofia Pro";
    src: url(${SofiaProRegular}) format('woff');
    font-weight: normal;
  }
  @font-face {
    font-family: "Sofia Pro";
    src: url(${SofiaProBold}) format('woff');
    font-weight: bold;
  }
  @font-face {
    font-family: "Sofia Pro";
    src: url(${SofiaProMedium}) format('woff');
    font-weight: 500;
  }
  @font-face {
    font-family: "Sofia Pro";
    src: url(${SofiaProMedium}) format('woff');
    font-weight: 600;
  }

  body {
    background-color: ${props => props.theme.colors.background};
    user-select: none;
    font-family: ${props => props.theme.typography.input.fontFamily};
  }
`;

export default GlobalStyle;
