import styled from 'styled-components';
export const Main = styled.main `
  position: relative;
  width: 100%;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
`;
export const MainContent = styled.div `
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
`;
