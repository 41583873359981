import { useState } from 'react';
function usePopover() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return {
        open,
        handleOpen,
        handleClose,
        setOpen,
    };
}
export default usePopover;
