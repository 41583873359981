import styled from 'styled-components';
export const Divider = styled.div `
  height: 1px;
  color: ${props => props.theme.colors.outline};
  background-color: ${props => props.color || props.theme.colors.outline};
  border: none;
  margin-bottom: 16px;
  margin-top: 16px;
`;
export default Divider;
