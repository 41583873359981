import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useAppNavigationContext } from '../AppNavigation';
import * as S from './styles';
function Navigation(props) {
    const { options } = useAppNavigationContext();
    const { title, left, right, extension, backgroundImage } = useMemo(() => {
        return Object.assign(Object.assign({}, props), options);
    }, [props, options]);
    return (_jsxs(S.Header, Object.assign({ backgroundImage: backgroundImage }, { children: [_jsxs(S.Wrapper, { children: [left ? _jsx(S.Left, { children: left }) : null, title ? _jsx(S.Title, { children: title }) : null, right ? _jsx(S.Right, { children: right }) : null] }), extension ? _jsx(S.Extension, { children: extension }) : null] })));
}
export default Navigation;
