import styled from 'styled-components';
import { Label } from '../Typography';
export const Container = styled.div `
  padding-top: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
`;
export const Heading = styled(Label) `
  margin-block-start: 0;
  margin-block-end: 0;
  color: ${props => props.theme.colors.onSurface};
`;
