import { createContext, useContext } from 'react';
export const SegmentedControlContext = createContext({});
export const useSegmentedControlContext = () => {
    const context = useContext(SegmentedControlContext);
    if (!context) {
        // eslint-disable-next-line no-console
        throw new Error('RadioGroupContext is undefined. No RadioGroupContext provider was found above it in the tree');
    }
    return context;
};
