import styled from 'styled-components';
export const Group = styled.div `
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  /* All labels except the first */
  & > * + input + label {
    margin-left: 10px;
  }
`;
export const Input = styled.input `
  display: none;

  /* Check radio label styles */
  &[type='radio']:checked + label {
    background: ${p => p.theme.colors.secondaryContainer};
    border-color: ${p => p.theme.colors.outlineSecondarySoft};
  }
`;
export const Label = styled.label `
  font-size: 18px;
  text-align: center;
  padding: 10px;
  flex-grow: 1;
  border: 1px solid ${p => p.theme.colors.outline};
  border-radius: 4px;
  color: ${p => p.theme.colors.onSurfaceSecondary};
  flex-grow: 1;
  flex-basis: 0;
`;
