import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import { Popover, PopoverContent, Button } from '..';
import * as S from './styles';
function ErrorDialog({ open, title, message, buttonText, illustration, onDismiss, }) {
    return (_jsxs(AnimatePresence, { children: [open && (_jsx(Popover, Object.assign({ open: open, onOpenChange: onDismiss }, { children: _jsx(motion.div, Object.assign({ initial: { y: '+100%', opacity: 0 }, animate: { y: 0, opacity: 1 }, exit: { y: '+100%', opacity: 0 }, transition: {
                        duration: 0.3,
                    } }, { children: _jsx(PopoverContent, { children: _jsxs(S.DialogCard, { children: [_jsxs(S.DialogCardContent, { children: [illustration, _jsx(S.Title, { children: title }), _jsx(S.Message, { children: message })] }), _jsx(S.DialogActions, { children: _jsx(Button, Object.assign({ color: 'success', onClick: onDismiss }, { children: buttonText })) })] }) }) })) }))), ";"] }));
}
export default ErrorDialog;
