import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, motion } from 'framer-motion';
import * as S from './styles';
function Dialog({ open, onDismiss, children, alignContent = 'center', bottomLinkText, onBottomLinkClick, }) {
    return (_jsx(AnimatePresence, { children: _jsx(S.Dialog, Object.assign({ alignContent: alignContent, onOpenChange: onDismiss, open: open }, { children: _jsx(motion.div, Object.assign({ initial: { y: '+100%', opacity: 0 }, animate: { y: 0, opacity: 1 }, exit: { y: '+100%', opacity: 0 }, transition: {
                    duration: 0.3,
                } }, { children: _jsxs(S.ScrollContainer, { children: [children, bottomLinkText && (_jsx(S.BottomLink, Object.assign({ onClick: onBottomLinkClick }, { children: bottomLinkText })))] }) })) })) }));
}
Dialog.Content = S.DialogCard;
Dialog.Actions = S.Actions;
Dialog.Title = S.Title;
Dialog.Message = S.Message;
export default Dialog;
