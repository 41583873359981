import styled from 'styled-components';
import { Header4, Paragraph } from '../Typography';
export const NavigationBarContainer = styled.div `
  min-height: 44px;
  /* https://css-tricks.com/the-notch-and-css/ */
  padding-top: env(safe-area-inset-top);
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  display: flex;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.onPrimary};
`;
export const NavigationBarExtensionContainer = styled.div `
  min-height: 32px;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.onPrimary};
`;
export const NavigationBarContentWrapper = styled.div `
  width: 100%;
`;
export const NavigationBarContentCenter = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;
export const NavigationBarContent = styled.div `
  position: relative;
  z-index: 0;
`;
export const NavigationBarExtensionBottomSpacer = styled.div `
  width: 100%;
  height: 32px;
`;
export const NavigationBarTitle = styled(Header4) `
  margin-block-start: 0;
  margin-block-end: 0;
`;
export const NavigationBarExtensionText = styled(Paragraph) `
  flex: 1;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
  width: 100%;
  overflow-wrap: break-word;
  padding: 0 16px;
`;
export const NavigationBarButton = styled.button `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  border-style: none;
  color: inherit;
  background-color: inherit;

  &:active {
    background-color: ${props => props.disabled ? 'inherit' : 'rgba(0, 0, 0, 0.08)'};
  }

  &:active:disabled {
    border-style: none;
  }

  &:focus {
    outline: none;
  }
`;
export const NavigationBarButtonLabel = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.disabled ? props.theme.colors.neutral300 : 'inherit'};
  font-family: ${props => props.theme.typography.paragraph.fontFamily};
  font-size: ${props => props.theme.typography.paragraph.fontSize};
  font-weight: ${props => props.theme.typography.paragraph.fontWeight};
  font-style: ${props => props.theme.typography.paragraph.fontStyle};
  font-stretch: ${props => props.theme.typography.paragraph.fontStretch};
  line-height: ${props => props.theme.typography.paragraph.lineHeight};
  letter-spacing: ${props => props.theme.typography.paragraph.letterSpacing};
`;
