var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled from 'styled-components';
import { ArrowRight16Icon } from '../icons';
const Card = styled.div `
  background: ${props => props.theme.colors.surfaceVariant};
  border: 1px solid ${props => props.theme.colors.outlineVariant};
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  color: ${props => props.theme.colors.onSurfaceVariant};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;
const ChevronRight = styled(ArrowRight16Icon) `
  stroke: ${props => props.theme.colors.onSurfaceVariant};
  height: 16px;
  width: 16px;
`;
export const CardSecondary = forwardRef(function CardSecondary(_a, ref) {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsxs(Card, Object.assign({ ref: ref }, props, { children: [_jsx("div", { children: children }), _jsx(ChevronRight, {})] })));
});
