import type { LatLngExpression, LatLngTuple } from 'leaflet';
import { useState } from 'react';
import {
  Circle,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
} from 'react-leaflet';

import * as S from './styles';

import placesAntwerpen from '@/assets/geodata/placesAntwerpen.json';
import placesGent from '@/assets/geodata/placesGent.json';
import zonesAalst from '@/assets/geodata/zonesAalst.json';
import zonesAntwerpen from '@/assets/geodata/zonesAntwerpen.json';
import { InfoDialog } from '@/components';
import { markerIcon } from '@/utils/customizer/markerIcon';

import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

const TT_API_KEY = 'rrNYUWHR2SlJBAAwNdO9wEnqTPegGYdK';
const USER_LOCATION = [51.21801614736447, 4.418040955879845] as LatLngTuple;

const convertGeoData = (s: string) => {
  const multipolygon: string[] | null = s.match(/\(\(.+?\)\)/g);
  const polygons = multipolygon?.map(p =>
    p.replace(/\(|\)/g, '').replace(/(,\s)/g, ','),
  );
  return polygons?.map(p =>
    p.split(',').map(c => {
      const coord = c.split(' ').map(x => Number(x));
      [coord[0], coord[1]] = [coord[1], coord[0]];
      return coord;
    }),
  );
};

function Overview() {
  const [code, setCode] = useState('');
  const [city, setCity] = useState('');
  const [cost, setCost] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <S.MapCard>
        <MapContainer center={USER_LOCATION} zoom={18}>
          <TileLayer
            url={`https://api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?tilesize=512&key=${TT_API_KEY}`}
            minZoom={11}
            maxZoom={21}
            detectRetina={true}
            keepBuffer={20}
          />
          {zonesAntwerpen.content.map(zone =>
            convertGeoData(zone.geometry)?.map((p, index) => (
              <Polygon
                key={`${zone.code} - ${index}`}
                positions={p as LatLngExpression[]}
                color='#FF4785'
                eventHandlers={{
                  click: () => {
                    setCode(zone.code);
                    setCity(zone.partner_name);
                    setCost(zone.max_cost);
                    setIsDialogOpen(true);
                  },
                }}
              />
            )),
          )}
          {zonesAalst.content.map(zone =>
            convertGeoData(zone.geometry)?.map((p, index) => (
              <Polygon
                key={`${zone.code} - ${index}`}
                positions={p as LatLngExpression[]}
                color='#FF4785'
                eventHandlers={{
                  click: () => {
                    setCode(zone.code);
                    setCity(zone.partner_name);
                    setCost(zone.max_cost);
                    setIsDialogOpen(true);
                  },
                }}
              />
            )),
          )}
          {placesAntwerpen.content.map(place =>
            place.coordinates.map((c, index) => (
              <Marker
                key={`${place.code} - ${index}`}
                position={[c.lat, c.lng]}
                icon={markerIcon}
                eventHandlers={{
                  click: () => {
                    setCode(place.code);
                    setCity(place.partner_name);
                    setCost(place.max_cost);
                    setIsDialogOpen(true);
                  },
                }}
              />
            )),
          )}
          {placesGent.content.map(place =>
            place.coordinates.map((c, index) => (
              <Marker
                key={`${place.code} - ${index}`}
                position={[c.lat, c.lng]}
                icon={markerIcon}
                eventHandlers={{
                  click: () => {
                    setCode(place.code);
                    setCity(place.partner_name);
                    setCost(place.max_cost);
                    setIsDialogOpen(true);
                  },
                }}
              />
            )),
          )}
          <Circle center={USER_LOCATION} radius={5}>
            <Popup>This is a mocked location as an example.</Popup>
          </Circle>
        </MapContainer>
      </S.MapCard>
      <InfoDialog
        code={code}
        city={city}
        cost={cost}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
}

export default Overview;
