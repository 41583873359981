import {
  AppNavigationProvider,
  Back24Icon,
  NavigationBarIconButton,
  Navigation,
  NavigationBarTitle,
} from 'pqbc-vas-design-system';
import { RemoveScroll } from 'react-remove-scroll';

import bridge from '../../bridge';
import { Routes } from '../index';

const App = () => {
  const handleCloseWebview = () => {
    void bridge.closeWebview();
  };

  return (
    <AppNavigationProvider>
      <RemoveScroll className='wrapper'>
        <Navigation
          title={<NavigationBarTitle>Maps API POC</NavigationBarTitle>}
          left={
            <NavigationBarIconButton onClick={handleCloseWebview}>
              <Back24Icon />
            </NavigationBarIconButton>
          }
          right={null}
        />
        <Routes />
      </RemoveScroll>
    </AppNavigationProvider>
  );
};

export default App;
