import { useEffect } from 'react';
import useAppNavigationContext from './useAppNavigationContext';
function useAppNavigation(props, deps = []) {
    const { setOptions } = useAppNavigationContext();
    useEffect(() => {
        setOptions(props);
        return () => {
            setOptions({});
        };
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
export default useAppNavigation;
